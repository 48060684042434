<template>
    <tr :class="s.tableRow">
        <td colspan="2">
            <TextInput
                :class="s.mtS"
                title="Invite User"
                placeholder="Enter user email or id"
                :value="formData.guid"
                :readonly="isMFADisabled"
                @input-text="formData.guid = $event"
            />
        </td>
        <td
            :class="theme.table.alignCenter"
            v-for="policy in availablePolicies"
            :key="policy"
            :data-label="policy.capitalize()"
        >
            <SwitchControl
                :class="s.mtS"
                :value="formData.policies[policy]"
                @change="setValue(policy, $event)"
                :disabled="policy === 'institution' && !isInstitutionalAllowed"
                :is-red="policy.toLowerCase() === 'finance'"
            />
        </td>
        <td :class="theme.table.alignCenter">
            <MfaDisabled v-if="isMFADisabled" :class="s.mtS">
                <template v-slot:text>
                    To enable account access <br/>
                    management, you need to <br/>
                    set up 2FA in your
                </template>
            </MfaDisabled>
            <Button
                :class="s.mtS"
                button-type="primary"
                @click="invite"
                :button-disabled="!formData.guid.trim()"
                v-else
                wide
            >
                <template>
                    Invite
                </template>
            </Button>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import MfaDisabled from 'Common/MfaDisabled.vue';
import ApiError from 'Entities/ApiError';
import { getRequests } from 'Store/v2/Notifications';
import theme from 'Theme';
import Button from 'Control/Button.vue';
import TextInput from 'Control/TextInput.vue';
import SwitchControl from 'Control/Switch.vue';

export default {
    name: 'AccountsSettings.InviteManager',
    components: {
        MfaDisabled,
        SwitchControl,
        Button,
        TextInput,
    },
    props: {
        managersGUIDs: {
            type: Array,
            default: () => [],
        },
        managerRequestsGUIDs: {
            type: Array,
            default: () => [],
        },
        isInstitutionalAllowed: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            theme,
            formData: {
                guid: '',
                policies: {},
            },
        };
    },
    computed: {
        ...mapGetters({
            isMFADisabled: 'Auth/isMFADisabled',
            availablePolicies: 'Accounts/availablePolicies',
        }),
    },
    methods: {
        async invite() {
            try {
                if (Object.entries(this.formData.policies).filter((policy) => policy[1]).length === 0) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Select at least one policy');
                    return;
                }
                if (this.managersGUIDs.includes(this.formData.guid) || this.managerRequestsGUIDs.includes(this.formData.guid)) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'You already have this manager, please edit your request if needed');
                    return;
                }
                const payload = {
                    recipient: this.formData.guid.trim(),
                    policies: Object.entries(this.formData.policies)
                        // first element of an array is name of the policy, and the second element is true or false (we need only selected policies)
                        .filter((policy) => policy[1])
                        .map(([policy]) => policy),
                };
                await this.$store.dispatch('Accounts/Management/shareAccountAccess', payload);
                this.formData.guid = '';
                Object.keys(this.formData.policies).forEach((policy) => {
                    this.formData.policies[policy] = false;
                });
            } catch (error) {
                this.formData.guid = '';
                Object.keys(this.formData.policies).forEach((policy) => {
                    this.formData.policies[policy] = false;
                });
                await this.$store.dispatch(getRequests(undefined));

                if (error instanceof ApiError) {
                    await this.$store.dispatch(
                        'Notificator/showErrorNotification',
                        error.data ? error.data.message : 'During sharing something went wrong. Please, try again later',
                    );
                }
            }
        },
        onChange({ event, policy }) {
            if (event === true && policy.toLowerCase() === 'finance') {
                this.openModal();
            }
        },
        openModal() {
            this.$emit('open', 'Invite');
        },
        cancelFinance() {
            this.formData.policies.finance = false;
            this.formData.policies = { ...this.formData.policies };
        },
        setValue(policy, e) {
            if (e === true && policy.toLowerCase() === 'finance') {
                this.openModal();
            }
            this.formData.policies[policy] = e;
            this.formData.policies = { ...this.formData.policies };
        },
    },
    watch: {
        availablePolicies(policies) {
            policies.forEach((policy) => {
                this.$set(this.formData.policies, policy, this.formData.policies[policy] || false);
            });
        },
    },
};
</script>

<style lang="postcss" module="s">
.tableRow {
    .mtS {
        margin-top: var(--m-s);
    }
}
</style>
