<template>
    <tr>
        <td>
            <div :class="s.userContainer">
                <Icon
                    :class="s.userIcon"
                    icon="user"
                    v-if="!isRequest"
                />
                <Icon
                    :class="s.userIcon"
                    icon="user_pending"
                    v-else
                />
                <p>
                    <span :class="s.userText">{{ fullName }}</span>
                    <Guid
                        :guid="authorGuid"
                        copied-value-name="User ID"
                    />
                </p>
            </div>
        </td>
        <td
            :class="theme.table.alignCenter"
        >
            <span
                :class="[s.statusText, { [s.green]: !isRequest, [s.yellow]: isRequest }]"
            >
                {{ isRequest ? 'Pending' : 'Active' }}
            </span>
        </td>
        <td
            :class="theme.table.alignCenter"
            v-for="policy in availablePolicies"
            :key="policy"
            :data-label="policy.capitalize()"
        >
            <SwitchControl
                :value="isEditing ? policies[policy] : managerPolicies[policy]"
                @change="setPolicy(policy, $event)"
                :disabled="!isEditing || (!isInstitutionalAllowed && !managerPolicies.institution && policy === 'institution')"
                :is-red="policy === 'finance'"
            />
        </td>
        <td :class="theme.table.alignCenter">
            <div :class="s.row">
                <template v-if="isEditing">
                    <Button
                        @click="saveChanges"
                        button-type="link"
                    >
                        <template>
                            Save
                        </template>
                    </Button>
                    <Button
                        @click="cancelChanges"
                        button-type="errorLink"
                    >
                        <template>
                            Cancel
                        </template>
                    </Button>
                </template>
                <template v-else>
                    <Button
                        @click="toggleEditing"
                        button-type="link"
                        :button-disabled="isMFADisabled"
                    >
                        <template>
                            Edit
                        </template>
                    </Button>
                    <Button
                        @click="deleteRecord"
                        button-type="errorLink"
                        :button-disabled="isMFADisabled"
                    >
                        <template>
                            {{ isRequest ? 'Cancel' : 'Delete' }}
                        </template>
                    </Button>
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import { AccountManagementPolicies } from 'Models/accounts';
import ShareAccountPayload from 'Entities/accountManagement/ShareAccountPayload';
import AccountsApi from 'Apis/Accounts';
import { ISSUE_MFA_ACTIONS } from 'Config/auth';
import router from '@/router';
import ApiError from 'Entities/ApiError';
import { getRequests } from 'Store/v2/Notifications';
import Icon from 'UI/Icon.vue';
import theme from 'Theme';
import Button from 'Control/Button.vue';
import SwitchControl from 'Control/Switch.vue';
import Guid from 'UI/Guid.vue';

export default {
    name: 'Accounts.PermissionsSettings.ManagersTable.ManagerRow',
    components: {
        Icon,
        Button,
        SwitchControl,
        Guid,
    },
    props: {
        manager: {
            type: Object,
            required: true,
        },
        isRequest: {
            type: Boolean,
            default: false,
        },
        currentRef: {
            type: String,
            default: '',
        },
        isInstitutionalAllowed: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            AccountManagementPolicies,
            isEditing: false,
            policies: {},
            theme,
        };
    },
    computed: {
        ...mapGetters({
            isMFADisabled: 'Auth/isMFADisabled',
            availablePolicies: 'Accounts/availablePolicies',
        }),
        author() {
            return this.manager.author;
        },
        fullName() {
            return `${this.author.firstName} ${this.author.lastName}`;
        },
        authorGuid() {
            return this.author.id;
        },

        managerPolicies() {
            return this.manager.policies;
        },

        requestGuid() {
            return this.isRequest ? this.manager.requestGuid : null;
        },
    },
    methods: {
        toggleOnEditing() {
            if (!this.isEditing) {
                this.isEditing = true;
            }
        },
        toggleOffEditing() {
            if (this.isEditing) {
                this.isEditing = false;
            }
        },
        toggleEditing() {
            if (this.isEditing) {
                this.toggleOffEditing();
            } else {
                this.toggleOnEditing();
            }
        },

        async saveChanges() {
            const newPolicies = [];

            Object.keys(this.policies).forEach((policy) => {
                if (this.policies[policy]) {
                    newPolicies.push(policy);
                }
            });
            if (newPolicies.length === 0) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Select at least one policy');
                return;
            }
            try {
                const startChallengePath = this.$router.history.current.path;
                const totp = await this.$store.dispatch('Auth/getMFAToken', { action: ISSUE_MFA_ACTIONS.CHANGE_ACCOUNT_ACCESS_PERMISSION });
                await router.replace(startChallengePath).catch(() => { /* navigation error */ });
                await AccountsApi.share(new ShareAccountPayload({
                    account: { id: this.$store.getters['Accounts/activeAccountID'] },
                    policies: newPolicies,
                    recipient: this.author.id,
                    totp,
                }));
                this.toggleOffEditing();
                await this.$store.dispatch('Notificator/showSuccessNotification', `Changing permissions request for user ${this.fullName} is sent`);
            } catch (error) {
                this.toggleOffEditing();
                await this.$store.dispatch(getRequests(undefined));

                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'During updating permissions something went wrong. Please, try again later');
                }
            }
        },
        cancelChanges() {
            this.toggleOffEditing();
        },

        deleteRecord() {
            if (this.isRequest) {
                this.cancelRequest();
            } else {
                this.deleteManager();
            }
        },
        deleteManager() {
            this.$store.dispatch('Accounts/Management/revokeAccountAccess', {
                guid: this.authorGuid,
            }).then(() => {
                this.$store.dispatch('Notificator/showSuccessNotification', 'Manager was successfully deleted');
            }).catch(() => {
                this.$store.dispatch('Notificator/showErrorNotification', 'During deleting something went wrong. Please, try again later');
            });
        },
        cancelRequest() {
            this.$store.dispatch('Accounts/Management/cancelAccountRequestAccess', {
                guid: this.requestGuid,
            }).then(() => {
                this.$store.dispatch('Notificator/showSuccessNotification', 'Access request was successfully canceled');
            }).catch(() => {
                this.$store.dispatch('Notificator/showErrorNotification', 'During canceling something went wrong. Please, try again later');
            });
        },
        setPolicy(policy, value) {
            this.policies[policy] = value;
            if (policy.toLowerCase() === 'finance' && value === true && this.isEditing) {
                this.openModal();
            }
        },
        openModal() {
            this.$emit('open', this.currentRef);
        },
        cancelFinance() {
            this.policies.finance = false;
        },
    },
    mounted() {
        this.policies = { ...this.managerPolicies };
    },
    watch: {
        availablePolicies(policies) {
            if (!this.formData) {
                return;
            }

            policies.forEach((policy) => {
                this.$set(this.formData.policies, policy, this.formData.policies[policy] || false);
            });
        },
    },
};
</script>

<style lang="postcss" module="s">
.userContainer {
    display: flex;
    align-items: center;
    column-gap: var(--m-s);
    & .userIcon {
        width: 36px;
        height: 36px;
    }
    & .userText {
        font-weight: var(--fw-regular);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        color: var(--cl-black);
        margin-bottom: var(--m-s);
    }
}
.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.statusText {
    font-size: var(--fs-m);
    font-weight: var(--fw-semibold);
    text-transform: capitalize;

    &.green {
        color: var(--cl-green);
    }

    &.yellow {
        color: var(--cl-yellow);
    }
}
</style>
